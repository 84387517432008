import { Luminous } from './luminous.min.js';

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});

document.querySelectorAll('.mobile-menu')[0].addEventListener('click', function(e) {
  document.querySelectorAll('.mobile-menu-list')[0].style.display = document.querySelectorAll('.mobile-menu-list')[0].style.display === 'block' ? '' : 'block'
})

document.querySelectorAll('.mobile-menu-list .header-menu__single a').forEach(element => {
  element.addEventListener('click', function(e) {
    console.log('click')
    document.querySelectorAll('.mobile-menu-list')[0].style.display = 'none'
  })
})
